.pf-c-tooltip.ncr-c-experimental-icon__tooltip {
  --pf-c-tooltip__content--Color: var(--pf-global--default-color--300);
  --pf-c-tooltip__content--BackgroundColor: var(--pf-global--warning-color--100);
  --pf-c-tooltip--BoxShadow: var(--pf-global--BoxShadow--lg);
  max-width: none;
}

.ncr-c-settings-form {

  > .pf-c-form__group {
    // --pf-c-form--m-horizontal__group-label--md--GridColumnWidth: 15rem;
    // --pf-c-form--m-horizontal__group-control--md--GridColumnWidth: 1fr;
  }

  h2.pf-c-title {
    margin-bottom:  var(--pf-global--spacer--lg);
  }

  .pf-c-switch.ncr-c-settings-form__experimental {
    --pf-c-switch__input--checked__toggle--BackgroundColor: var(--pf-global--warning-color--100);
    --pf-c-switch__input--focus__toggle--OutlineColor: var(--pf-global--warning-color--100);
    --pf-c-switch__input--focus__toggle--OutlineOffset: 0.25rem;
  }
}

button.ncr-m-experimental {

  i.mdi {
    position: relative;
    overflow: visible;

    &::after {
      display: block;
      content: ' ';
      width: 8px;
      height: 8px;
      top: -1px;
      right: -1px;
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg width='8px' height='8px' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='%23F0AB00' stroke='%23FFFFFF' stroke-width='3' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    }
  }
}
