.ncr-c-estimate {
    .ncr-c-estimate__flow {
        .ncr-c-estimate__flow-list {
            border: none;
        }

        .ncr-c-estimate__flow-main {
            .ncr-c-estimate__interval {

                .pf-c-date-picker,
                .pf-c-number-input {
                    width: 100%;
                }

                border: none;

                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }

            .ncr-c-estimate__interval-value,
            .ncr-c-estimate__interval-label {
                width: 200px;

                .pf-c-select__menu {
                    width: 500px;
                }
            }

            .ncr-c-estimate__interval-direction {
                width: 120px;
            }
        }
    }

    .pf-c-date-picker.ncr-m-error input {
        padding-right: var(--pf-c-form-control--invalid--PaddingRight);
        padding-bottom: var(--pf-c-form-control--invalid--PaddingBottom);

        border-bottom-color: var(--pf-c-form-control--invalid--BorderBottomColor);
        border-bottom-width: var(--pf-c-form-control--invalid--BorderBottomWidth);
    }

    .pf-c-select button {
        .pf-c-select__toggle-status-icon {
            display: none;
        }
    }

    .ncr-c-netto-brutto-input-group.ncr-m-error {
        border-bottom: 2px solid var((--pf-global--danger-color--100));
    }
}

.ncr-estimate-flow-interval-select > .pf-c-select__menu {
    min-width: 100% !important;
}
