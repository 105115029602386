@import "functions";
@import "variables";

.pf-c-page__header.ncr-c-navbar {
  grid-template-columns: var(--pf-c-page__sidebar--Width) auto;

  .pf-c-page__header-content {
    padding-right: var(--pf-global--spacer--md);
  }


  .pf-c-avatar {
    margin-left: var(--pf-global--spacer--md);

    &.ncr-m-experimental {
      border-width: var(--pf-global--BorderWidth--md) !important;
      border-color: var(--pf-global--warning-color--100) !important;
    }
  }

  .pf-c-page__header-brand-link {
    display: inline-block;
    position: relative;
    flex: 0 1 auto;
    padding-top: 4px;
    &:hover svg {
      fill: var(--pf-global--palette--blue-300);
    }
    svg {
      height: 1rem;
      fill: var(--pf-global--palette--white);
    }
    .pf-c-label {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(110%) translateY(-50%);
      padding: 1px 4px 0;
      .pf-c-label__content {
        font-weight: bold;
        font-size: 0.6rem;
        text-transform: uppercase;
        color: var(--pf-c-label__icon--Color);
      }
    }
  }
}

.ncr-c-search.pf-c-text-input-group {

  background-color: var(--pf-global--BackgroundColor--light-100);
  margin-right: var(--pf-global--spacer--md);

  .pf-c-text-input-group__text {

    --pf-c-text-input-group__icon--Color: var(--pf-global--Color--light-200);

    &:hover, &:focus-within {
      --pf-c-text-input-group__icon--Color: var(--pf-global--Color--light-300);
    }
  }

  input {
    color: var(--pf-global--Color--dark-100);
  }
  button {
    color: var(--pf-global--Color--dark-200);
  }
}

.ncr-c-navbar .ncr-c-cluster-switch-dropdown {
  margin-right: calc(var(--pf-global--spacer--sm) * -1); // balancing visual weight because of dropdown toggle indicator

  .pf-c-dropdown__menu {
    min-width: 150px;
  }
  .pf-c-dropdown__group-title {
    padding-top: 0;
    padding-bottom: var(--pf-global--spacer--xs);
  }

  .pf-c-dropdown__toggle {
    padding-left: 0;
    padding-right: 0;

    .pf-c-dropdown__toggle-icon {
      margin-left: var(--pf-global--spacer--xs);
      margin-right: 0;
      opacity: $ncr-inactive-icon-opacity;
      transition: transition(opacity, out);
    }

    &:hover, &:focus {
      .pf-c-dropdown__toggle-icon {
        opacity: 1;
        transition: transition(opacity, in);
      }
    }
  }
}

.ncr-u-plain > .pf-c-dropdown__toggle {
  &::before {
    border: none;
  }

  & button::before,
  & button > i::before {
    border-bottom: none;
    border-top: none;
  }
}
