.pf-c-data-list.ncr-c-datev {
    border: none;

    .ncr-c-datev__fragment {
        border: none;

        .pf-c-data-list__item-row {
            padding-left: 0px;
        }


        .ncr-c-datev__fragment__name {
            color: var(--pf-global--Color--200);
        }

        .ncr-c-datev__attachment {
            margin-bottom: var(--pf-global--spacer--xs);

            .ncr-c-attachment__icon,
            .ncr-c-datev__attachment__checkbox {
                margin-right: var(--pf-global--spacer--sm);
            }
        }
    }
}