.ncr-c-drawer-panel__properties {

  .pf-c-drawer__panel-main {
  }

  .ncr-c-drawer-panel-properties__estimate,
  .ncr-c-drawer-panel-properties__timesheet {
    padding-bottom: var(--pf-global--spacer--sm);
  }

  .ncr-c-drawer-panel-properties__estimate .pf-c-button.pf-m-secondary.pf-u-w-100,
  .ncr-c-drawer-panel-properties__timesheet .pf-c-button.pf-m-secondary.pf-u-w-100 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .ncr-c-drawer-panel-properties__estimate .pf-c-button .ncr-c-estimate-title__interval,
  .ncr-c-drawer-panel-properties__timesheet .pf-c-button .ncr-c-timesheet-title__interval {
    font-size: 85%;
  }

  .ncr-c-drawer-panel-properties__estimate .ncr-c-drawer-panel-properties__estimate-reset i,
  .ncr-c-drawer-panel-properties__timesheet .ncr-c-drawer-panel-properties__timesheet-reset i {
    opacity: 0;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
  }

  .ncr-c-drawer-panel-properties__estimate:hover .ncr-c-drawer-panel-properties__estimate-reset i,
  .ncr-c-drawer-panel-properties__timesheet:hover .ncr-c-drawer-panel-properties__timesheet-reset i {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

}
