@import "variables";

.pf-c-popover.c-chart {
  box-shadow: none;
  .pf-c-popover__content {
    padding-top: var(--pf-global--spacer--sm) !important;
    padding-bottom: var(--pf-global--spacer--sm) !important;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 0.5em;
    border: rgba(0, 0, 0, 0.25) 1px solid;
  }

  .pf-c-popover__arrow {
    display: none;
  }
}

.pf-c-page__main-section:has(.ncr-c-overview-and-explain) {
  overflow-y: scroll !important;
}

.ncr-c-overview-and-explain {
 .pf-c-expandable-section__content {
   margin-top: 0;
 }
  .pf-c-table tr > * {
    padding: 0.25rem 0.25rem;
  }
}

.ncr-c-chart__totals_header {
  font-size: 1.1em;
}
.ncr-c-chart__totals {
  margin-top: var(--pf-global--spacer--xs);
  font-size: 0.75em;

  $ncr-c-chart--background-color: #ccc;

  tr > td, tr > th {
    &:nth-child(n+3) {
      border-left: 1px solid $ncr-c-chart--background-color;
      border-right: 1px solid $ncr-c-chart--background-color;
    }
  }

  tr:last-child > td:not(:first-child), tr > th:not(:first-child) {
    border-bottom: 1px solid $ncr-c-chart--background-color;
  }
  tr:last-child > td:not(:first-child) {
    border-top: 1px solid $ncr-c-chart--background-color;
  }

  th {
    text-align: center;
    padding: 0 var(--pf-global--spacer--xs);
  }

  .ncr-c-chart__header_main {
     th {
       font-size: 1em;
       &:not(:empty) {
         background-color: $ncr-c-chart--background-color;
       }
     }
  }

  td.ncr-c-total__value {
    font-family: var(--pf-global--FontFamily--monospace);
    text-align: right !important;
  }

  tr > td {
    &:first-child {
      font-weight: bold;
    }
    padding: 0 var(--pf-global--spacer--sm);
  }

  td > i.mdi {
    float: left;
    margin-right: var(--pf-global--spacer--sm);
  }

  .ncr-c-chart__header__with_controls > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0 var(--pf-global--spacer--xs);
    }
  }

  .ncr-c-total__income {
    color: $ncr--money_income--text-color;
  }

  .ncr-c-total__expense {
    color: $ncr--money_expense--text-color;
  }

  .ncr-c-total {
    margin-left: var(--pf-global--spacer--sm);
    font-weight: normal;
  }

  .ncr-c-total__estimated,  .ncr-c-total__remaining {
    color: $ncr--estimate--text-color;
  }
  .ncr-c-chart__spacer {
    width: 1.5em !important;
    border: none !important;
  }

}
