:root {
  --ncr-c-checkbox-with-highlight--Size: 48px;
}

#ncr-c-search-list .pf-c-data-list__checkbox {
  --ncr-c-checkbox-with-highlight--Size: 48px;
}

$ncr-checkbox-height: 13px;

.ncr-c-bulk-selection__fragment-attachments {
  .pf-c-data-list__checkbox {

    margin-right: var(--pf-global--spacer--xs);
    margin-top: 0;

    --ncr-c-checkbox-with-highlight--Size: 36px;

    > .pf-c-dropdown__toggle-check {
      line-height: $ncr-checkbox-height;
    }
  }
  .pf-c-data-list__item-content {
    padding-bottom: 0;
  }

  .ncr-c-bulk-selection__fragment-attachments--fragment-title {
    --pf-c-data-list__item-row--PaddingLeft: 16px;
    font-size: var(--pf-global--FontSize--md);
    .pf-c-data-list__checkbox {
      --ncr-c-checkbox-with-highlight--Size: 40px;
    }
  }
}

.pf-c-data-list__checkbox,
.ncr-c-checkbox-with-highlight {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.pf-c-data-list__checkbox {
  height: var(--ncr-c-checkbox-with-highlight--Size);
  width: calc(var(--ncr-c-checkbox-with-highlight--Size) * 0.5);
  margin-right: calc(var(--ncr-c-checkbox-with-highlight--Size) * 0.5);
  margin-top: 4px;
}

.pf-c-data-list__checkbox .pf-c-data-list__item-control {
  margin: 0;
  padding: 0;
}

.pf-c-data-list__checkbox .pf-c-data-list__check {
  padding: 0;
  margin: 0;
  height: unset;
}


.pf-c-data-list__checkbox::before,
.ncr-c-checkbox-with-highlight::before {
  opacity: 0;
  content: "";
  line-height: 1;
  background-color: rgba(32, 33, 36, 0.059);
  border: none;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc((var(--ncr-c-checkbox-with-highlight--Size) - $ncr-checkbox-height) * -0.5);
  transform: scale(1);
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.pf-c-data-list__checkbox::before {
  bottom: 0;
  right: 14px;
  top: 0;
}

.pf-c-data-list__checkbox::before,
.ncr-c-checkbox-with-highlight::before {
  height: var(--ncr-c-checkbox-with-highlight--Size);
  width: var(--ncr-c-checkbox-with-highlight--Size);
}

.pf-c-data-list__checkbox:hover::before,
.ncr-c-checkbox-with-highlight:hover::before {
  opacity: 1;
}
