.ncr-c-inline-edit {

    .ncr-c-inline-edit__truncate,
    .ncr-c-inline-edit__truncate span {
        min-width: 0;
    }

    .ncr-c-inline-edit__icon {
        padding-left: 5px;
    }

    .ncr-c-inline-edit__hint {
        cursor: pointer;
        border-bottom: 1px dashed;
    }
}