@use "sass:map";
@import "@patternfly/patternfly/sass-utilities/colors";
@import "functions";

.ncr-c-attachment-viewer {

  .pf-c-toolbar__content {
    padding-right: 0;
  }

  .pf-c-empty-state__body {
    overflow-wrap: anywhere;
  }

  .pf-c-select__menu-item {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.ncr-c-attachment-viewer__body {
  position: relative;
}

.ncr-c-attachment-viewer__content {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.ncr-c-attachment-viewer-scrollable .ncr-c-attachment-viewer__content {
  overflow: auto;
}

.ncr-c-attachment-viewer__toolbar-container {
  margin-bottom: 0 !important;
  box-shadow: var(--pf-c-page--section--m-sticky-top--BoxShadow);
  z-index: var(--pf-c-page--section--m-sticky-top--ZIndex);
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;

  .pf-c-toolbar {
    display: flex;
    flex: 1 1 auto;
    max-width: 100%;
  }

  .pf-c-toolbar__content, .pf-c-toolbar__content-section {
    display: flex;
    flex-wrap: nowrap;
  }

  .pf-c-toolbar__content {
    flex-grow: 1;
    max-width: 100%;
  }

  .ncr-c-attachment-viewer__close {
    display: flex;
    flex: 0 0 auto;
  }

  .pf-c-toolbar__content.pf-m-hidden, .pf-c-toolbar__expandable-content {
    display: none;
  }

}

.ncr-c-attachment-viewer__right-toolbar {
  flex: 1 1 0;
  min-width: 0;
  justify-content: flex-end;

  .ncr-c-attachment-viewer__attachment-selector {
    display: flex;
    flex: 0 1 auto;
    min-width: 0;

    .pf-u-max-width {
      /* lg is the breaking point for left drawer */
      --pf-u-max-width--MaxWidth: 400px;

      --pf-u-max-width--MaxWidth-on-xl: 375px;
      --pf-u-max-width--MaxWidth-on-2xl: 600px;
    }

    .pf-c-select__menu .pf-u-max-width {
      /* lg is the breaking point for left drawer */
      --pf-u-max-width--MaxWidth: 400px;

      --pf-u-max-width--MaxWidth-on-xl: 400px;
      --pf-u-max-width--MaxWidth-on-2xl: 625px;
    }
  }

  .ncr-c-attachment-viewer__close-button {
    flex: 0 0 auto;
    margin-top: 18px;
  }
}


.ncr-c-attachment-viewer__navigation,
.ncr-c-attachment-viewer__navigation>button {
  display: flex;
  flex: 1 1 0;
  min-width: 0;
}

.ncr-c-attachment-viewer__navigation.ncr-m-deleted .pf-c-select__toggle-text,
.ncr-c-attachment-viewer__navigation .pf-c-select__menu-item.ncr-m-deleted {
  text-decoration: line-through;
}

.ncr-c-pdf-viewer {
  overflow: auto;
  position: absolute;
  width: 100%;
  height: 100%;

  .pdfViewer>.page {
    z-index: 1;
    border-image: url("../images/shadow.png") 9 9 repeat;
  }

  .ncr-c-ocr-page {
    z-index: 2;
  }
}

.ncr-c-pdf-viewer .page {
  box-sizing: content-box;
}

.ncr-c-ocr-page {
  border-width: 1px;
  border-style: solid;
  border-color: var(--pf-global--BorderColor--200);
  mix-blend-mode: multiply;
}

.ncr-c-ocr-page__number {
  font-size: 85%;
  padding: 4px;
}

@mixin ocr-block-style($color) {
  border-width: 2px;
  border-style: solid;

  border-color: rgba($color, 0.3);
  background-color: rgba($color, 0.1);
  transition: transition(all, out-fast);

  &:hover {
    border-color: rgba($color, 0.7);
    background-color: rgba($color, 0.3);
    transition: transition(all, in);
  }
  &.ncr-c-ocr-block__active {
    border-color: rgba($color, 1);
    background-color: rgba($color, 0.5);
    transition: transition(all, in);
  }
}

.ncr-c-ocr-block__word {
  border-radius: var(--pf-global--BorderRadius--sm);
  @include ocr-block-style($pf-color-black-100);
  border-color: rgba($pf-color-black-100, 0);
  background-color: rgba($pf-color-black-100, 0);
}

.ncr-c-ocr-block__money {
  @include ocr-block-style($pf-color-green-300)
}

.ncr-c-ocr-block__date {
  @include ocr-block-style($pf-color-blue-300)
}

.ncr-c-ocr-block__page {
  border: 1px solid green;
}

.ncr-c-img-viewer {
  position: relative;
  width: 100%;
  height: 100%;
}

.ncr-c-img-viewer-viewer .openseadragon-message {
  display: none !important;
}

.ncr-c-img-viewer-scroll-cover {
  overflow: scroll;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.ncr-c-img-viewer-scroll-inner {
  position: relative;
}

.ncr-c-img-viewer-navigator-container {
  box-sizing: content-box;
  border: 1px solid var(--pf-global--BorderColor--200);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-color: var(--pf-global--BackgroundColor--100);
  visibility: visible;

  &.fade-in {
    opacity: 1;
    transition: opacity 0.5s;
  }
  &.fade-out {
    opacity: 0;
    transition: opacity 0.75s;
  }
  &.hidden {
    visibility: hidden;
  }
}

.ncr-c-img-viewer-navigator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.ncr-c-img-viewer-selection {
  cursor: move;
  border: 1px solid red;
  position: absolute;
  z-index: 20;
}

.ncr-c-text-viewer {
  word-break: break-all;
  white-space: pre-wrap;
  border: 9px solid transparent;

  border-image: url("../images/shadow.png") 9 9 repeat;
  padding: 16px;
}
