.pf-c-modal-box__title-text>h3 {
  font-size: 85%;
  color: var(--pf-global--Color--400);
}

#new-fragment-modal .pf-c-modal-box__header {
  padding-bottom: var(--pf-global--spacer--md);
}

#estimate-props-modal {
  height: 75%;

  .pf-c-form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

#timesheet-props-modal {
 // width: 75rem;
  // --pf-c-modal-box--m-md--Width: 52.5rem; too small,
  // --pf-c-modal-box--m-lg--lg--MaxWidth: 70rem; too big

  --pf-c-modal-box--Width: 55rem;
  min-height: 85%;

  .pf-c-modal-box__body {
    display: flex;
    flex-direction: column;
  }
}

#zip-dialog {
  .pf-c-modal-box__body {
    display: flex;
    flex-direction: column;

    .pf-c-panel.pf-m-scrollable {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
    }
  }
}
