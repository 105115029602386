.ncr-report-explain-grid {

    tbody tr:last-child {
        border: none !important;
    }

    th {
        &.c-name {
            width: 45%;
            font-weight: bold;
        }

        &.c-value {
            width: 15%;
        }

        &.c-value,
        &.c-time,
        &.c-team-total,
        &.c-team-time {
            text-align: right;
        }
    }

    td {
        &.c-value,
        &.c-time,
        &.c-team-total,
        &.c-team-time {
            text-align: right;

            &.t-summary:not(:empty) {
                &.m-income-border {
                    border-top: solid var(--pf-global--success-color--100);
                }

                &.m-expense-border {
                    border-top: solid var(--pf-global--danger-color--200);
                }

                &.m-income-color {
                    color: var(--pf-global--success-color--100);
                }

                &.m-expense-color {
                    color: var(--pf-global--danger-color--200);
                }

            }
        }

        &.c-name {
            &.t-data {
                vertical-align: middle;

                &.pf-m-flex-none {
                    flex: 1;
                    max-width: inherit;
                }

                &.pf-m-flex-none,
                &.pf-c-label-group {
                    min-width: 0;
                }

                .ncr-u-text-truncate__container {
                    min-width: 75px !important;
                    flex-grow: 0 !important;
                    display: flex;
                    justify-content: center;
                }

                .pf-c-label {
                    font-size: var(--pf-global--FontSize--xs) !important;
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
            }
        }

        &.c-time,
        &.c-team-time {

            &.t-data,
            &.t-summary,
            button>span {
                color: var(--pf-global--primary-color--200);
            }
        }

        &.c-value.t-data .pf-c-form__group {
            margin-left: calc(-1 * var(--pf-global--spacer--xs));
            margin-right: calc(-2 * var(--pf-global--spacer--sm));
        }

        &.c-value,
        &.c-time,
        &.c-team-time {
            .pf-c-button {
                font-size: inherit;
                padding: var(--pf-global--spacer--xs) var(--pf-global--spacer--sm);
                margin-top: calc(-1 * var(--pf-global--spacer--xs));
                margin-bottom: calc(-1 * var(--pf-global--spacer--xs));
            }

            .pf-c-form-control {
                height: auto;
            }

            .pf-c-form-control,
            .pf-c-input-group__text {
                font-size: inherit;
            }

            .ncr-m-inline-edit {
                margin-right: -1.5rem;
            }

            .ncr-m-inline-edit:hover .pf-c-button__icon,
            .ncr-m-inline-edit .ncr-m-visible {
                opacity: 1;
                -webkit-transition: opacity 0.25s ease !important;
                transition: opacity 0.25s ease !important;
            }

            .ncr-m-inline-edit .pf-c-button__icon {
                color: var(--pf-global--Color--300);
                opacity: 0;
                -webkit-transition: opacity 0.75s ease !important;
                transition: opacity 0.75s ease !important;
            }
        }
    }
}
