#history-dialog {

  .ncr-c-events-list {
    dd {
      margin-left: var(--pf-global--spacer--md);
      margin-bottom: var(--pf-global--spacer--sm);
    }
  }

  .ncr-c-events-list__header {
    display: block;
  }
  .ncr-c-events-list__header_name {
    font-weight: bold;
    font-size: 0.9em;

    color: #888;
  }
  .pf-c-label-group__list {
    margin-left: 0;
    margin-top: 0;
  }

  .pf-c-label-group__list-item {
    margin-top: 0;
    margin-bottom: 0;
  }
}
