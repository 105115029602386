@import "@mdi/font/scss/materialdesignicons";
@import "@patternfly/patternfly/patternfly";
@import "@patternfly/patternfly/patternfly-addons";
@import "github-markdown-css/github-markdown-light.css";
@import "react-autocomplete-input/dist/bundle.css";
@import "pdfjs-dist/web/pdf_viewer.css";

@import "patternfly-deslash";
@import "extensions";
@import "prism.css";
@import "ncr";
@import "navbar";
@import "sidebar";
@import "preferences";
@import "modals";
@import "attachment-viewer";
@import "properties_panel";
@import "inputs";
@import "charts";
@import "report_grid";
@import "report_explain";
@import "attachment_icons";
@import "attachment_label";
@import "attachment_card";
@import "inline_edit";
@import "datev";
@import "estimate";
@import "checkbox_highlight";
@import "tour";
@import "eventlog";

.pf-c-about-modal-box {
  --pf-c-about-modal-box__hero--sm--BackgroundImage: url(./../images/river.png);
}

.pf-c-background-image,
.pf-c-login {
  --pf-c-background-image--BackgroundImage: url(./../images/river.png);
  --pf-c-background-image--BackgroundImage-2x: url(./../images/river.png);
  --pf-c-background-image--BackgroundImage--sm: url(./../images/river.png);
  --pf-c-background-image--BackgroundImage--sm-2x: url(./../images/river.png);
  --pf-c-background-image--BackgroundImage--lg: url(./../images/river.png);
}

.pf-c-login {
  background-image: url(./../images/river.png);
}
