@import "functions";
@import "variables";

.pre-debug {
  border: 1px solid red;
  padding: 4px;
  margin: 4px;
  font-size: 12px;
  display: block;
}

button.ncr-m-inline-edit {

  padding-left:  var(--pf-global--spacer--sm); // equals to `--pf-c-form-control--inset--base`
  padding-right:  var(--pf-global--spacer--sm);

  &.pf-m-link {
    transition: transition(all, out);

    &:hover {
      text-decoration: underline;
      transition: transition(all, in);
    }

    &:hover .pf-c-button__icon,
    &.pf-m-disabled .pf-c-button__icon {
      opacity: 1;
      transition: transition(opacity, in);
    }

    .pf-c-button__icon {
      color: var(--pf-global--Color--300);
      opacity: $ncr-inactive-icon-opacity;
      transition: transition(opacity, out);
    }
  }
}

.pf-c-popover.inline-edit {
  --pf-c-popover--FontSize: inherit; // --pf-global--FontSize--md;
}

.ncr-c-prop-label--money_netto-brutto.ncr-c-prop-label--blurred {
  .value {
    filter: blur(var(--ncr-m-blur));
  }
}

.ncr-m-inline-edit--netto-brutto {

  input {
    width: 140px;
    // negate jumping validation icon
    padding-right: var(--pf-global--spacer--xl) !important;
  }

  .pf-c-form__group.vat {
    padding-left: 4px;
    input {
      width: 100px;
    }

    .pf-c-chip {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  $view-mode-border: rgba($pf-color-black-100, 0.75);
  $brutto-background-border: rgba($pf-color-black-100, 0.5);

  button.brutto {
    background-color: $brutto-background-border;
  }

  transition: transition(border-color, out);
  display: flex;
  flex-flow: row nowrap;

  .vat-switch {
    height: 100%;
    padding-top: 0px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;

    &.disabled {
      visibility: hidden;
    }

    .vat-enabled {
      color: $pf-color-blue-400;
    }

    &:hover .vat-enabled {
      color: $pf-color-blue-600;
    }

    .vat-disabled {
      color: $pf-color-black-100;
    }

    &:hover .vat-disabled {
      color: $pf-color-black-300;
    }
  }

  .pf-c-form__label-text {
    .pf-c-button {
      padding-bottom: 0;
      padding-left: 4px;
      margin-left: -4px;
      padding-top: 4px;
      margin-top: -4px;
      color: $pf-color-blue-300;
      font-size: var(--pf-c-form__label--FontSize) !important;

      &:hover {
        color: $pf-color-blue-400;
      }
    }
  }


  .ncr-m-inline-edit--netto-brutto--currency {
    margin-right: $pf-global--spacer--md;
    margin-left: $pf-global--spacer--sm !important;
  }

  .ncr-m-inline-edit--netto-brutto--values {
    display: flex;
    flex-flow: row nowrap;
  }

  &.ncr-c-netto-brutto-label {
    border: 1px solid $view-mode-border;
    background-color: transparent;
    width: auto !important;

    .pf-c-button {
      --pf-c-button--FontSize: var(--pf-global--FontSize--sm) !important;
    }

    .pf-c-input-group__text {
      background: transparent;
    }

    &:hover {
      border-color: $pf-color-blue-300;
      transition: transition(border-color, in);
    }

    & > .brutto {
      background-color: $brutto-background-border;
      border: none;
      border-radius: 0;
    }

    & > * {
      padding: 0 8px !important;

      margin-top: -1px;
      margin-bottom: -1px; // compensation for layout glitch

      &.ignored-missing {
        padding: 0 !important;
      }
    }

    .value-unknown {
      color: $pf-color-black-300;
    }
  }

  .ncr-m-inline-edit--add-on {
    padding: 0;
  }

  &:hover {
    .icon-container > .mdi-pencil {
      opacity: 1 !important;
      transition: transition(opacity, in);
    }

    .icon-container > .mdi-currency-eur {
      opacity: 0;
      transition: transition(opacity, in);
    }
  }

  .icon-container {
    position: relative;

    color: var(--pf-c-input-group__text--Color);

    & > .mdi-pencil {
      opacity: 0;
      transition: transition(opacity, out);
    }

    & > .mdi-currency-eur {
      opacity: 1;
      transition: transition(opacity, out);
      position: absolute;
      left: 0;
      top: 48.5%;
      transform: translateY(-50%);
    }
  }
}

.pf-c-popover.inline-edit {
  .pf-c-popover__content {
    padding-right: 0; // compensate for "cancel" button
    padding-top: var(--pf-global--spacer--sm) !important;
    padding-bottom: var(--pf-global--spacer--sm) !important;

    .button-save {
      padding-right: var(--pf-global--spacer--sm);
      &.pf-m-disabled {
        --pf-c-button--disabled--Color: var(--pf-global--disabled-color--200);
      }
    }
    .button-cancel {
      padding-left: var(--pf-global--spacer--sm);
    }
  }

  .pf-c-popover__arrow {
    display: none;
  }
}

.pf-c-calendar-month tbody {
  height: 256px;
}
