.ncr-c-attachment-card {
    width: 400px;
    --pf-c-card--m-selectable-raised--m-selected-raised--TranslateY--base: 0;
    --pf-c-card--m-selectable-raised--before--Height: 3px;
    --pf-c-card__title--FontWeight: var(--pf-global--FontWeight--normal);

    .pf-c-card__header {
        cursor: pointer;

        -webkit-transition: all 0.2s ease !important;
        transition: all 0.2s ease !important;
    }

    .pf-c-card__footer {
        &.ncr-c-attachment-card__name-deleted span {
            text-decoration: line-through;
        }
        
    }

    &:hover .pf-c-card__title .mdi {
        visibility: visible;
    }

    &:has(.ncr-c-inline-edit .ncr-c-inline-edit__input) {
        .ncr-c-attachment-card__header {
            padding: 0 6px 0 6px;
        }

        .ncr-c-attachment-card__name {
            padding-bottom: 6px;
            padding-right: 4px;
            padding-left: 4px;
        }

        .pf-c-card__actions {
            display: none;
        }

    }
}

/*
.ncr-c-attachment-card:before {
    -webkit-transition: all 0.5s ease !important;
    transition: all 0.5s ease !important;
}

.ncr-fragment-attachments .pf-c-card:hover .pf-c-button.pf-m-link {
    color: var(--pf-c-button--m-link--hover--Color);
}

.ncr-c-attachment-card .ncr-c-attachment-card__status .label {
    font-size: 85%;
    opacity: 65%;
    display: inline-block;
    padding-left: 5px;
}

.ncr-c-attachment-card-edited .ncr-c-attachment-card__status {
    transform: scale(0.8);
}

.ncr-c-attachment-cards-list li .ncr-c-attachment-card__status {

    margin-top: -6px; // identical to .pf-c-card__actions
    margin-bottom: -6px;

    .ncr-c-attachment-card__status-icon {
        padding: 0;
        visibility: visible;

        &.mdi {
            font-size: 21px;
        }
    }
}
*/