@import "functions";

#page-sidebar .ncr-c-no-dropzone {
    border: var(--pf-global--BorderWidth--sm) solid transparent;
}

#page-sidebar .ncr-c-dropzone {
    border: var(--pf-global--BorderWidth--sm) solid transparent;

    &.ncr-m-drag-hover {
        background-color: var(--pf-global--primary-color--100);
        border: var(--pf-global--BorderWidth--sm) solid var(--pf-global--primary-color--100);
    }
}

#page-sidebar .pf-c-badge {
    margin-left: 8px;
}

.ncr-c-nav-list__labels {
    .pf-c-nav__item {
        .pf-c-nav__link {

            padding-right: var(--pf-global--spacer--sm);

            a {
                color: inherit;

                &:hover {
                    text-decoration: none;
                }
            }

            .pf-c-button {
                padding-top: 0;
                padding-bottom: 0;
            }

            &:hover .pf-c-button {
                opacity: 1;
                transition: transition(opacity, in);
            }
        }
    }

    .ncr-c-nav-list--label__pinned {
        .pf-c-button {
            opacity: 1;
            color: #777777;

            &:hover {
                color: #f5f2f0;
            }
        }
    }

    .ncr-c-nav-list--label__unpinned {
        .pf-c-button {
            color: #999999;
            opacity: 0;
            transition: transition(opacity, out-fast);

            &:hover {
                opacity: 1;
                transition: transition(opacity, in);
            }
        }
    }
}