.pf-c-tooltip.ncr-c-attachment-label__tooltip {
    --pf-c-tooltip__content--Color: var(--pf-global--default-color--300);
    --pf-c-tooltip__content--BackgroundColor: var(--pf-global--BackgroundColor--100);
    --pf-c-tooltip--BoxShadow: var(--pf-global--BoxShadow--lg);
    max-width: none;
}

.ncr-c-attachment-label {
    &.pf-c-label {
        --pf-c-label--BorderRadius: 4px;
        padding-top: 0;
        padding-bottom: 0;

        --pf-c-label__content--before--BorderWidth: 1px;
        --pf-c-label__content--link--hover--before--BorderWidth: 1px;
        --pf-c-label__content--link--focus--before--BorderWidth: 1px;
    }

    button.pf-c-label {
        padding: 0 8px;
    }

    .pf-c-label__icon>.ncr-c-attachment__icon {
        margin-right: calc(var(--pf-global--spacer--xs) * 0.5);

        &:last-of-type {
            margin-right: 0;
        }
    }

    .pf-c-truncate {
        // default value for `--pf-c-label__text-MaxWidth` is 16ch (16 widths of "0" character)
        // Truncate component splits in two parts when `content.slice(0, content.length - trailingNumChars).length > 12`
        // (see https://github.com/patternfly/patternfly-react/blob/2ae96429eb4cb7501aac517d1220c4d0a8f957a8/packages/react-core/src/components/Truncate/Truncate.tsx#L65)
        // `trailingNumChars` is 7 by default, so it starts splitting when content length is longer than 19
        // means that `--pf-c-label__text-MaxWidth` should be at least 19ch to fit the whole content when splitting is not triggered
        --pf-c-label__text--MaxWidth: 19ch;
        max-width: var(--pf-c-label__text--MaxWidth);
        min-width: 0;
    }
}
