@import "variables";

:root {
    --ncr-m-blur: 3px;
    --ncr-inactive-icon-opacity: #{$ncr-inactive-icon-opacity};
}

#app {
    height: 100%;
}

.ncr-u-border-bottom {
    border-bottom: var(--pf-global--BorderWidth--sm) solid var(--pf-global--BorderColor--100);
}

.ncr-u-border-left {
    border-left: var(--pf-global--BorderWidth--sm) solid var(--pf-global--BorderColor--100);
}

.pf-c-label-group .pf-c-label.pf-m-outline span.pf-c-label__content:hover {
    --pf-c-label__content--before--BorderWidth: var(--pf-c-label--m-outline__content--link--hover--before--BorderWidth);
    --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-outline__content--link--hover--before--BorderColor);
}

.pf-c-tooltip[data-popper-placement="bottom-end"] {
    // ugly hack to hide tooltips with specific placement
    // required if overriding default tooltips which are impossible to disable or hide otherwise
    display: none;
}

.pf-c-label.ncr-c-prop-label {
    --pf-c-label--BorderRadius: 0;
}

.ncr-c-prop-list {
    align-items: center;

    &>.ncr-c-prop-label--money:nth-child(2) {
        margin-right: 16px !important;
    }

    li {
        margin-right: 0;
        margin-left: 0;

        &:not(:last-child) {
            margin-right: var(--pf-global--spacer--sm);
        }
    }
}

.ncr-c-attachment-list {
    .pf-c-label.pf-m-overflow {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.pf-c-empty-state .pf-c-title {
    color: var(--pf-c-empty-state__body--Color);
}

.pf-c-list.pf-m-inline li[role="separator"] {
    margin-right: 0;
}

.pf-c-list.ncr-u-hide-empty li:empty {
    display: none;
}

.ncr-m-no-pointer-events {
    pointer-events: none;
}

.ncr-m-cursor-pointer {
    cursor: pointer;
}

.ncr-m-visibility-hidden {
    visibility: hidden;
}

.ncr-m-disabled {
    color: var(--pf-global--disabled-color--200);
}

.ncr-c-dropzone.pf-c-nav__link,
.ncr-c-dropzone.pf-c-nav__link:hover {
    //  border: var(--pf-global--BorderWidth--sm) solid red;
}

//.ncr-c-dropzone.ncr-m-drag-hover {
//background-color: var(--pf-global--palette--blue-50);
//}

.ncr-c-dropzone-target {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--pf-global--palette--blue-50);
    opacity: 0.8;
    z-index: 999;
}

.ncr-folder-dropdown-menu {
    margin-left: auto;
}

.pf-c-nav__link .ncr-folder-dropdown-menu {
    visibility: hidden;
}

.pf-c-nav__link:hover .ncr-folder-dropdown-menu {
    visibility: visible;
}

.pf-c-alert-group.pf-m-toast {
    left: var(--pf-global--spacer--lg);
    right: unset !important;
    bottom: var(--pf-global--spacer--lg);
    top: unset !important;
    max-width: calc(100% - var(--pf-c-alert-group--m-toast-Right) * 2);
    width: auto !important;
    min-width: 20rem;
}

.ncr-c-code-editor textarea {
    outline: none;
}

.pf-c-avatar.pf-m-small {
    --pf-c-avatar--Width: 1.75rem;
    --pf-c-avatar--Height: 1.75rem;
}

.pf-c-data-list__item-content {
    min-width: 0;
}

.ncr-u-text-truncate__container {
    min-width: 0;
    flex-grow: 1;
}


.pf-c-button-selectable-text {
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.pf-c-button__link:not(:hover) {
    color: var(--pf-global--Color--100);
}

.ncr-c-search-list__item-name:hover a {
    color: var(--pf-global--link--Color--hover);
}

.pf-c-data-list__item.ncr-c-search-list__separator {
    background-color: var(--pf-global--BackgroundColor--200);
}

.ncr-c-search-list__separator_today_empty {
    font-size: 85%;
    background-color: var(--pf-global--active-color--200);
}

.ncr-c-search-list__separator_today {
    background-color: var(--pf-global--active-color--200);
}

.ncr-c-search-list__separator_today_empty .pf-c-data-list__item-content {
    padding-bottom: 4px !important;
}

.ncr-c-search-list__separator_today_empty .pf-c-data-list__cell {
    padding-top: 4px;
}

.ncr-search-truncated {
    color: var(--pf-global--Color--light-300);
    padding-left: 8px;
}

.ncr-search-truncated i {
    padding-right: 2px;
}

.ncr-info-icon {
    margin: 0 6px;
}

.ncr-search-cheat-sheet .pf-c-description-list {
    --pf-c-description-list--RowGap: calc(var(--pf-global--gutter--md) * 1.5);
}

.ncr-search-cheat-sheet .pf-c-description-list__text {
    width: 100%;
}

.ncr-search-cheat-sheet .pf-c-code-block {
    margin-right: var(--pf-global--spacer--md);
}

.ncr-search-cheat-sheet .pf-c-code-block__content {
    position: relative;
    padding-right: var(--pf-global--spacer--2xl);
}

.ncr-search-cheat-sheet .pf-c-code-block__content {
    padding-right: var(--pf-global--spacer--md);
}

.ncr-search-cheat-sheet-code {
    display: flex;
    align-items: baseline;
}

.ncr-search-cheat-sheet-code-actions {
    margin-left: auto;
}

.ncr-search-cheat-sheet-code-actions .pf-c-button {
    opacity: 0.2;
    padding-left: var(--pf-global--spacer--form-element);
    padding-right: var(--pf-global--spacer--form-element);
}

.ncr-search-cheat-sheet-code-actions .pf-c-button:hover {
    opacity: 1;
}

.ncr-search-cheat-sheet-popover {
    left: 13px !important;
}

.ncr-new-fragment-attachments {
    display: flex;
    flex-direction: column;
}

.ncr-fragment-attachments {
    display: flex;
    flex: auto;
    position: relative;
}

.ncr-fragment-attachments .ncr-c-dropzone {
    flex: auto;
}

.ncr-empty-search {
    height: 100%;
}

.ncr-c-inline-edit__text {
    cursor: text;
}

.pf-c-tooltip .ncr-c-inline-edit__text {
    text-decoration: none;
}

.ncr-c-inline-edit__text--saving {
    color: var(--pf-global--Color--200);
    text-decoration: none;
    cursor: pointer;
}

.ncr-c-inline-edit .pf-c-chip-group__list {
    margin-top: 2px;
    margin-left: 4px;
}

.pf-m-icon-group {
    padding-left: 6px;
    padding-right: 0;
}

.pf-m-icon-group button {
    padding-left: 6px;
    padding-right: 6px;
}



.pf-c-page__main-section>.pf-c-toolbar .pf-c-dropdown__toggle.pf-m-disabled {
    background-color: transparent;
}

.pf-c-page__main-section>.pf-c-toolbar .pf-c-dropdown__toggle.pf-m-disabled button {
    color: var(--pf-global--disabled-color--200);
}

.pf-c-page__main-section>.pf-c-toolbar .pf-c-dropdown__toggle.pf-m-disabled button:nth-child(2) {
    color: var(--pf-global--disabled-color--300);
}

.pf-c-page__main-section>.pf-c-toolbar .pf-c-dropdown__toggle button {
    color: var(--pf-global--Color--200);
}

.pf-c-page__main-section>.pf-c-toolbar .pf-c-dropdown__toggle button:hover {
    color: var(--pf-global--Color--100);
}

.pf-c-page__main-section>.pf-c-toolbar .pf-c-dropdown__toggle.pf-m-action::before {
    border: none;
}

.pf-c-page__main-section>.pf-c-toolbar .pf-c-dropdown__toggle.pf-m-action::before,
.pf-c-page__main-section>.pf-c-toolbar .pf-c-dropdown__toggle.pf-m-action button::before,
.pf-c-page__main-section>.pf-c-toolbar .pf-c-dropdown__toggle.pf-m-action button>i::before {
    border-bottom: none;
    border-top: none;
}

.pf-c-page__main-section>.pf-c-toolbar .pf-c-dropdown__toggle-button:nth-child(2) {
    padding-left: 2px;
    padding-right: 2px;
}

.pf-c-page__main-section>.pf-c-toolbar .pf-c-dropdown__toggle-button:nth-child(2)::before {
    border-right: none;
}



.ncr-permissions-select .pf-c-select__menu-group-title#Users {
    display: none;
}

.ncr-c-property-timesheet__person-select > .pf-c-select__menu {
    min-width: 100% !important; // override min-width set by select menu to truncate long entries
}

.ncr-pf-c-select-option-label-truncated {
    overflow: hidden;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.nrc-label-select .pf-c-select__menu {
    --pf-c-select__menu--Width: 100%;
}

.ncr-m-blurred {
    filter: blur(var(--ncr-m-blur));
    -webkit-filter: blur(var(--ncr-m-blur));
}

.ncr-c-total-amount.ncr-m-blurred {
    --ncr-m-blur: 5px;
}

#ncr-c-fragment-body .pf-c-drawer__content {
    min-width: 614px;
}

.ncr-date-prop-calendar .pf-c-popover__body {
    display: flex;
    padding-left: 12px;
}

.ncr-date-prop-calendar .pf-c-calendar-month {
    padding-left: 8px;
}

.ncr-date-prop-calendar-today-btn {
    padding: 8px 8px;
    margin-top: 21px;
}

.ncr-c-property-interval .pf-l-split__item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ncr-c-property-interval .pf-c-date-picker__input:last-child,
.ncr-c-property-interval .ncr-c-property-interval__label,
.ncr-c-property-estimates__interval .ncr-c-property-estimates__direction,
.ncr-c-property-estimates__interval .ncr-c-property-estimates__value-row {
    padding-bottom: calc(var(--pf-global--FontSize--md) * var(--pf-global--LineHeight--md) + 1px);
}

.ncr-c-property-interval__label-count {
    color: var(--pf-global--Color--400);
}


.ncr-c-property-estimates__interval .pf-c-card__title {
    width: 100%;
}

.ncr-c-property-estimates__interval-title {
    display: flex;
    flex-flow: row;
    width: 100%;
}

.ncr-c-property-estimates__interval-title .ncr-c-property-estimates__interval-title__intervals {
    font-weight: normal;
    flex: 1 1 auto;
}

.ncr-c-property-estimates__interval-title .ncr-c-property-estimates__interval-title__total {
    font-weight: normal;
    color: var(--pf-global--disabled-color--100) !important;
}

.ncr-c-property-estimates,
.ncr-c-property-timesheet {
    max-height: 100%;
    min-height: 0;
    &.pf-c-form {
        display: flex;
        flex-flow: column;
    }
}

.ncr-c-property-estimates>.pf-c-form__field-group,
.ncr-c-property-timesheet>.pf-c-form__field-group {
    min-height: 0;
}

.ncr-c-property-estimates>.pf-c-form__field-group>.pf-c-form__field-group-body,
.ncr-c-property-timesheet>.pf-c-form__field-group>.pf-c-form__field-group-body {
    padding-right: var(--pf-global--spacer--md);
    padding-bottom: 0;
    padding-top: 0;
    overflow-y: auto;
}

.ncr-c-property-timesheet__interval,
.ncr-c-property-estimates__estimate {
    border-radius: var(--pf-global--BorderRadius--sm);
    padding-bottom: var(--pf-global--spacer--md);
    margin-bottom: var(--pf-global--spacer--md) !important;
}

.ncr-c-property-timesheet__interval {
    border-width: 0;
    --pf-global--gutter: 8px;

    .ncr-c-property-interval__label {
        white-space: nowrap;
    }

    & > .pf-c-form__field-group-body {

        padding-top: 0 !important;

        & > .ncr-c-property-timesheet__interval-header {
            background-color: var(--pf-global--BackgroundColor--100);
            border: var(--pf-global--BorderColor--100) 1px solid;
            position: sticky;
            padding-top: 16px;
            padding-left: 16px;
            top: 0;
            z-index: 1;

            .ncr-c-property-timesheet__interval-header__index {
                margin-bottom: 20px;
                padding-bottom: 5px;
                padding-right: 10px;
                margin-right: 10px;
                color: var(--pf-global--disabled-color--100);
                border-right: var(--pf-global--BorderColor--100) 1px solid;

                .ncr-c-property-timesheet__interval-header__index-value {
                    color: var(--pf-global--Color--100);
                    font-weight: bold;
                }
            }
            .ncr-c-property-timesheet__interval-toolbar {
                align-items: flex-start;
            }

            & > .pf-c-form__field-group-header {
                padding: 0 8px 16px;
                margin-top: -4px;
            }
        }

        & > .ncr-c-property-timesheet__interval-footer {
            & > .pf-c-form__field-group-header {
                flex-direction: row-reverse;
                padding: 0 8px 16px;
                margin-top: -4px;
                .pf-c-form__field-group-header-title {
                    padding-left: 16px;
                }
            }
        }

        & > .pf-c-form__field-group {
            border: var(--pf-global--BorderColor--100) 1px solid;
            border-top-width: 0;

            .pf-c-form__field-group-body {
                padding-top: 24px;
            }
        }
        .pf-c-form__field-group-header-title {
            top: 64px;
        }
    }
}

.ncr-c-property-timesheet__interval>.pf-c-form__field-group-body,
.ncr-c-property-estimates__estimate>.pf-c-form__field-group-body {
    padding-top: var(--pf-global--spacer--md);
    padding-bottom: 0;
}

.ncr-c-property-estimates__estimate>.pf-c-form__field-group-body .ncr-c-property-estimates__estimate-toolbar {
    margin-top: calc(-0.5 * var(--pf-global--spacer--md));
    padding-bottom: calc(0.5 * var(--pf-global--spacer--md));
}

.ncr-c-property-estimates__estimate>.pf-c-form__field-group-body>.pf-c-form__field-group,
.ncr-c-property-timesheet__interval>.pf-c-form__field-group-body>.pf-c-form__field-group {
    margin-top: calc(-1.5 * var(--pf-global--spacer--md));
}

.ncr-c-property-estimates__estimate>.pf-c-form__field-group-body>.pf-u-w-100,
.ncr-c-property-estimates__estimate>.pf-c-form__field-group-body .pf-c-form__field-group-header,
.ncr-c-property-timesheet__interval>.pf-c-form__field-group-body>.pf-u-w-100,
.ncr-c-property-timesheet__interval>.pf-c-form__field-group-body .pf-c-form__field-group-header {
    padding-left: var(--pf-global--spacer--md);
    padding-right: var(--pf-global--spacer--md);
}

.ncr-c-property-timesheet__interval .ncr-c-property-timesheet__interval-toolbar {
    padding-bottom: var(--pf-global--spacer--md);
}

.ncr-c-property-estimates__estimate .ncr-c-property-estimates__estimate-toolbar,
.ncr-c-property-estimates__interval .ncr-c-property-estimates__interval-toolbar,
.ncr-c-property-timesheet__interval .ncr-c-property-timesheet__interval-toolbar,
.ncr-c-property-timesheet__task .ncr-c-property-timesheet__task-toolbar {
    opacity: 0;
    -webkit-transition: opacity 0.75s ease !important;
    transition: opacity 0.75s ease !important;
}

.ncr-c-property-timesheet__task-list .pf-c-form__field-group-body {
    --ncr-c-gutter: var(--pf-global--gutter);
    gap: var(--ncr-c-gutter);
}

.ncr-c-property-timesheet__task-group.pf-l-split {
    // negate row-gap of the grid
    margin-bottom: calc(var(--ncr-c-gutter) * -0.5) !important;

    padding-left: calc(2* var(--pf-global--spacer--md));

    background-color: var(--pf-global--BackgroundColor--150);
    &:hover {
        background-color: var(--pf-global--BackgroundColor--200) !important;
    }

    line-height: 1;
    font-size: var(--pf-global--FontSize--sm);
    font-weight: bold;

    .ncr-c-property-timesheet__task-group__title {
        justify-content: flex-start;
        width: 232px; //attempt to align with the rest of the grid items
    }
    .ncr-c-property-timesheet__task-group__value-primary {
        justify-content: flex-start;
        width: 180px; //attempt to align with the rest of the grid items
    }
    .ncr-c-property-timesheet__task-group__value {
        justify-content: flex-start;
        padding-left: var(--pf-global--spacer--sm);
    }
}

.ncr-c-property-estimates__estimate:hover .ncr-c-property-estimates__estimate-toolbar,
.ncr-c-property-estimates__interval:hover .ncr-c-property-estimates__interval-toolbar,
.ncr-c-property-timesheet__interval:hover .ncr-c-property-timesheet__interval-toolbar,
.ncr-c-property-timesheet__task:hover .ncr-c-property-timesheet__task-toolbar {
    opacity: 1;
    -webkit-transition: opacity 0.2s ease !important;
    transition: opacity 0.2s ease !important;
}

.ncr-c-property-estimates__estimate,
.ncr-c-property-timesheet__interval {
    border-color: var(--pf-global--BorderColor--100) !important;
    -webkit-transition: border-color 0.75s ease !important;
    transition: border-color 0.75s ease !important;
}

.ncr-c-property-estimates__estimate:hover,
.ncr-c-property-timesheet__interval:hover {
    border-color: var(--pf-global--active-color--100) !important;
    -webkit-transition: border-color 0.2s ease !important;
    transition: border-color 0.2s ease !important;
}

.ncr-c-property-estimates__estimate:last-child,
.ncr-c-property-timesheet__interval:last-child {
    margin-bottom: 0;
}

.ncr-c-property-estimates__estimate .pf-l-split-item,
.ncr-c-property-timesheet__interval .pf-l-split__item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ncr-c-property-estimates__interval,
.ncr-c-property-timesheet__task {
    position: relative;
    background-color: transparent;
    padding-top: var(--pf-global--spacer--xs) !important;
    margin-top: calc(-1 * var(--pf-global--spacer--xs)) !important;
    padding-bottom: var(--pf-global--spacer--xs) !important;
    margin-bottom: calc(-1 * var(--pf-global--spacer--xs)) !important;
    padding-left: var(--pf-global--spacer--md) !important;
    margin-right: var(--pf-global--spacer--md) !important;
    -webkit-transition: background-color 0.75s ease !important;
    transition: background-color 0.75s ease !important;
}

.ncr-c-property-estimates__interval-indicator,
.ncr-c-property-timesheet__task-indicator {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    background-color: var(--pf-global--active-color--100);
    -webkit-transition: all 0.2s ease !important;
    transition: all 0.2s ease !important;
}

.ncr-c-property-estimates__interval:hover,
.ncr-c-property-timesheet__task:hover {
    background-color: var(--pf-global--BackgroundColor--150);
    -webkit-transition: background-color 0.2s ease !important;
    transition: background-color 0.2s ease !important;
}

.ncr-c-property-estimates__interval:hover .ncr-c-property-estimates__interval-indicator,
.ncr-c-property-timesheet__task:hover .ncr-c-property-timesheet__task-indicator {
    width: var(--pf-global--spacer--xs);
    -webkit-transition: all 0.1s ease !important;
    transition: all 0.1s ease !important;
}

input.ncr-c-property-estimates__value {
    width: 100px;
}

.ncr-c-property-estimates__direction {
    --pf-c-switch__input--checked__toggle--BackgroundColor: var(--pf-global--success-color--200);
    --pf-c-switch__toggle--BackgroundColor: var(--pf-global--danger-color--200);
    --pf-c-switch__input--checked__label--Color: #151515;
    --pf-c-switch__input--not-checked__label--Color: #151515;
}

#estimate-props-modal .ncr-c-estimate-title__interval,
#timesheet-props-modal .ncr-c-timesheet-title__interval {
    font-size: inherit;
}

.ncr-c-property-timesheet .ncr-c-timesheet-title__interval {
    font-size: revert;
}

.ncr-c-property-timesheet__dropdown .pf-c-menu__content {
    min-width: 200px; // negate width jumping on checking items
    .pf-c-dropdown__group-title {
        padding-bottom: 0;
    }
}

.ncr-c-timesheet-title__interval-count,
.ncr-c-timesheet-title__tasks>i,
.ncr-c-timesheet-title__value>i,
.ncr-c-estimate-title__interval-count,
.ncr-c-estimate-title__value>i,
.ncr-c-estimate-title__periodic-value>i,
.ncr-c-estimate-title__count>i {
    color: var(--pf-global--disabled-color--100);
}

button .ncr-c-timesheet-title__interval-count,
button .ncr-c-timesheet-title__tasks>i,
button .ncr-c-timesheet-title__value>i,
button .ncr-c-estimate-title__interval-count,
button .ncr-c-estimate-title__value>i,
button .ncr-c-estimate-title__periodic-value>i,
button .ncr-c-estimate-title__count>i {
    color: inherit;
}

.ncr-c-timesheet-title__person,
.ncr-c-timesheet-title__value,
.ncr-c-timesheet-title__tasks {
    margin-left: var(--pf-global--spacer--xs);
}

.ncr-c-timesheet-title.wrapped,
.ncr-c-estimate-title.wrapped {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.ncr-c-timesheet-title.wrapped .ncr-c-timesheet-title__person,
.ncr-c-estimate-title.wrapped .ncr-c-estimate-title__value>i {
    margin-left: 0;
    padding-left: 0 !important;
}

.ncr-c-estimate-title__labels {
    margin-left: var(--pf-global--spacer--xs);
}

.ncr-input-folder-selector .pf-c-select__toggle-typeahead {
    max-width: 110%;
    min-width: 110%;
}

.c-context-menu .pf-c-popover__arrow {
    display: none;
}

.ncr-recent {
    margin-top: 2px;
    margin-left: 4px;
}


/* ncr grid */
.ncr-grid {
    position: relative;
}

.ncr-grid td,
.ncr-grid td button {
    font-size: 10pt;
    font-variant-numeric: tabular-nums lining-nums;
}

.ncr-grid th {
    background-color: var(--pf-c-table--BackgroundColor);
    border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor);
}

.ncr-grid .ncr-extendable,
.ncr-grid .ncr-expandable {
    cursor: pointer;
}

.ncr-grid .ncr-extendable:hover,
.ncr-grid .ncr-expandable:hover {
    color: #000 !important;
}

.ncr-grid .ncr-u-money-income {
    color: $ncr--money_income--text-color;
}

.ncr-grid .ncr-u-money-expense {
    color: $ncr--money_expense--text-color;
}

.ncr-grid .ncr-u-money-pnl {
    font-weight: bold;
}

/* ncr grid  end */

.ncr-m-valign-middle {
    vertical-align: middle;
}

div#storybook-root:has(.pf-l-stack) {
    height: 100%;
}

.nrc-ai-assist-popover footer button {
    margin-right: 8px;
}
